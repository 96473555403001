import React, { useEffect, useState, useRef } from 'react';
import './ChatBar.css';
import { useMyContext } from '../../context/ProjectProvider';
import { BlockMath, InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Models from './Models'; // Asegúrate de que esta importación tenga la ruta correcta
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';


const ChatBar = () => {
    const [inputValue, setInputValue] = useState('');
    const [displayedMessages, setDisplayedMessages] = useState([]);
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const [isStreaming, setIsStreaming] = useState(false);
    const [isTimeoutActive, setIsTimeoutActive] = useState(false); // New state for tracking timeout
    const [userScrolled, setUserScrolled] = useState(false);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [hasSentAutoMessage, setHasSentAutoMessage] = useState(false);
    const [isChatBarExpanded, setIsChatBarExpanded] = useState(false); // Estado para expandir o contraer la barra de chat


    const inputRef = useRef(null);
    //const messagesEndRef = useRef(null); comentado por warning
    const chatMessagesRef = useRef(null);
    const abortControllerRef = useRef(null);
    const displayedMessagesRef = useRef([]);
    const userMessageRef = useRef(null);

    const handleModelSelect = (model) => {
        setCurrentProject((prevProject) => {

            return{
                ...prevProject,
                lastModelUsed: model
            }
            
        })
    };

    const handleToggleChatBar = () => {
        setIsChatBarExpanded((prevState) => !prevState); // Cambia el estado de expansión
    };

    const {
        id,
        currentSub,
        currentProject,
        setCurrentProject,
        fetchProjectDetails,
        fetchProjects,
        saveProject,
        resetSelections,
        defaultModel,
        defaultMessage,
        productSpecificationsSelections
    } = useMyContext();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (inputRef.current) {
            adjustTextareaHeight();
        }
    }, [inputValue]);


    useEffect(() => {

        if (id==="1" && !hasSentAutoMessage && currentProject.messages.length === 0) {


            //handleSendMessage(defaultMessage);
            setHasSentAutoMessage(true);
            
        }
    },[id, currentProject.messages, hasSentAutoMessage, defaultMessage]); //agragado defaultMessage por warning


    useEffect(() => {
        setHasSentAutoMessage(false);
    }, [currentProject.conversationId]);

    useEffect(() => {
        if (!userScrolled && chatMessagesRef.current) {
            chatMessagesRef.current.scrollTo({
                top: chatMessagesRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [displayedMessages, currentProject.messages, userScrolled]);

    const adjustTextareaHeight = () => {
        const textarea = inputRef.current;
        textarea.style.height = 'auto';
        requestAnimationFrame(() => {
            const maxHeight = 180;
            textarea.style.height = `${Math.min(textarea.scrollHeight, maxHeight)}px`;
        });
    };
    

    const handleSendMessage = async (messageText) => {
        const message = (messageText !== undefined ? messageText : inputValue).trim();
        if (message && !isTimeoutActive && !isSendingMessage) {
            setIsSendingMessage(true);
            const newMessage = {
                role: 'user',
                parts: [message],
            };
    
            userMessageRef.current = newMessage; // Store user's message
    
            setCurrentProject((prevProject) => {
                const updatedProject = {
                    ...prevProject,
                    messages: [...prevProject.messages, newMessage],
                };
    
                if (messageText === undefined) {
                    setInputValue('');
                }
                setDisplayedMessages([]);
                setUploadedImage(null);
    
                displayedMessagesRef.current = [];
    
                sendMessage({ currentProject: updatedProject, onMessageUpdate, onStreamEnd, uploadedImage });
    
                return updatedProject;
            });
        }
    };


    const onMessageUpdate = (deltaData) => {
        if (deltaData.new_message) {
            setDisplayedMessages(prevDisplayedMessages => {
                const newDisplayedMessages = [
                    ...prevDisplayedMessages,
                    { role: deltaData.role, parts: [''] }
                ];
                displayedMessagesRef.current = newDisplayedMessages; // Update the ref
                return newDisplayedMessages;
            });
        } else if (deltaData.text_delta) {
            setDisplayedMessages(prevDisplayedMessages => {
                let newDisplayedMessages;
                if (prevDisplayedMessages.length === 0) {
                    newDisplayedMessages = [{ role: 'model', parts: [deltaData.text_delta] }];
                } else {
                    const lastMessage = prevDisplayedMessages[prevDisplayedMessages.length - 1];
                    const updatedMessage = {
                        ...lastMessage,
                        parts: [lastMessage.parts[0] + deltaData.text_delta]
                    };
                    newDisplayedMessages = [...prevDisplayedMessages.slice(0, -1), updatedMessage];
                }
                displayedMessagesRef.current = newDisplayedMessages; // Update the ref
                return newDisplayedMessages;
            });
        } else if (deltaData.simulation_name_delta || deltaData.code_description_delta || deltaData.executable_python_code_delta) {
            setDisplayedMessages(prevDisplayedMessages => {
                let newDisplayedMessages;
                if (prevDisplayedMessages.length === 0) {
                    newDisplayedMessages = [{ role: 'model-simblock', parts: [deltaData] }];
                } else {
                    const lastMessage = prevDisplayedMessages[prevDisplayedMessages.length - 1];
                    let updatedParts = lastMessage.parts[0];
                    if (typeof updatedParts === 'string') {
                        updatedParts = {};
                    }
                    if (deltaData.simulation_name_delta) {
                        updatedParts.simulation_name = (updatedParts.simulation_name || '') + deltaData.simulation_name_delta;
                    }
                    if (deltaData.code_description_delta) {
                        updatedParts.code_description = (updatedParts.code_description || '') + deltaData.code_description_delta;
                    }
                    if (deltaData.executable_python_code_delta) {
                        updatedParts.executable_python_code = (updatedParts.executable_python_code || '') + deltaData.executable_python_code_delta;
                    }
                    const updatedMessage = {
                        ...lastMessage,
                        parts: [updatedParts]
                    };
                    newDisplayedMessages = [...prevDisplayedMessages.slice(0, -1), updatedMessage];
                }
                displayedMessagesRef.current = newDisplayedMessages; // Update the ref
                return newDisplayedMessages;
            });
        } else if (deltaData.progress_update) {
            // Handle progress update messages
            setDisplayedMessages(prevDisplayedMessages => {
                let newDisplayedMessages;
                if (
                    prevDisplayedMessages.length === 0 ||
                    prevDisplayedMessages[prevDisplayedMessages.length - 1].role !== 'system-progress_update'
                ) {
                    // Start a new progress update message
                    newDisplayedMessages = [
                        ...prevDisplayedMessages,
                        { role: 'system-progress_update', parts: [deltaData.progress_update] }
                    ];
                } else {
                    // Append to the last progress update message
                    const lastMessage = prevDisplayedMessages[prevDisplayedMessages.length - 1];
                    const updatedMessage = {
                        ...lastMessage,
                        parts: [deltaData.progress_update] // Replace with the latest progress update
                    };
                    newDisplayedMessages = [...prevDisplayedMessages.slice(0, -1), updatedMessage];
                }
                displayedMessagesRef.current = newDisplayedMessages; // Update the ref
                return newDisplayedMessages;
            });

        } else if (deltaData.notification) {
            setDisplayedMessages(prevDisplayedMessages => {
                let newDisplayedMessages;
                const lastMessage = prevDisplayedMessages[prevDisplayedMessages.length - 1];
                    if (
                        lastMessage.role === 'system-notification' &&
                        lastMessage.parts[0] === ''
                    ) {
                        // Update the last message's parts with the notification text
                        const updatedMessage = {
                            ...lastMessage,
                            parts: [deltaData.notification]
                        };
                        newDisplayedMessages = [...prevDisplayedMessages.slice(0, -1), updatedMessage];
                    } else {
                        // Add a new message
                        newDisplayedMessages = [
                            ...prevDisplayedMessages,
                            { role: 'system-notification', parts: [deltaData.notification] }
                        ];
                    }
                displayedMessagesRef.current = newDisplayedMessages;
                return newDisplayedMessages;
            });
        }

    
       
    };

    const onStreamEnd = async () => {
        setIsStreaming(false);
        setIsSendingMessage(false);

        const lastMessage = displayedMessagesRef.current[displayedMessagesRef.current.length - 1];

        // Check if the last message is a "system-notification" starting with "You have reached"
        if (lastMessage?.role === 'system-notification' && lastMessage.parts[0].startsWith('You have reached')) {

            setIsTimeoutActive(true); // Disable send button during timeout

            // Delay both fetching and clearing displayedMessages only for this specific case
            setTimeout(async () => {
                // Fetch latest projects and project details after delay
                
                await fetchProjects(false, currentSub, id);
                await fetchProjectDetails(currentProject.conversationId);

                // Clear displayedMessages after fetching
                setDisplayedMessages([]);
                displayedMessagesRef.current = []; // Clear the ref

                setIsTimeoutActive(false); // Re-enable send button after timeout
            }, 7000); // Delay by 5 seconds (adjust as needed)
        } else {
            // For all other cases, fetch and clear immediately
            await fetchProjects(false, currentSub, id);
            await fetchProjectDetails(currentProject.conversationId);

            setDisplayedMessages([]);
            displayedMessagesRef.current = []; // Reset the ref
        }
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleKeyPress = (e) => {
        console.log("")
    };

    const handleScroll = () => {
        const chatMessages = chatMessagesRef.current;
        if (chatMessages) {
            const isAtBottom =
                Math.abs(chatMessages.scrollHeight - chatMessages.clientHeight - chatMessages.scrollTop) < 1;
            setUserScrolled(!isAtBottom);
        }
    };

    const handleDownButtonClick = () => {
        if (chatMessagesRef.current) {
            chatMessagesRef.current.scrollTo({
                top: chatMessagesRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
        setUserScrolled(false);
    };

    const stopProcess = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
            abortControllerRef.current = null;
            
            //cleanup is not handled here. its handled in the catchbock of send message
        }
    };

    const handleCleanup = async () => {
        setIsStreaming(false);
        setIsSendingMessage(false);
    
        // Create an updatedProject that includes the user's message and the displayedMessages
        const updatedProject = {
            ...currentProject,
            messages: [
                ...currentProject.messages,
                userMessageRef.current, // Append user's message
                ...displayedMessagesRef.current // Then append displayed messages
            ],
        };
    
        // Clear displayedMessages and refs before updating the state
        setDisplayedMessages([]);
        displayedMessagesRef.current = [];
        userMessageRef.current = null;
    
        // Update currentProject in context
        setCurrentProject(updatedProject);
    
        // Save the updated project
        await saveProject(updatedProject, true, true);
    
        // Fetch latest projects and project details
        await fetchProjects(false, currentSub, id);
        await fetchProjectDetails(currentProject.conversationId);
    };
    

    const handleStopButtonClick = () => {
        if (isStreaming) {
            stopProcess();
        }
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setUploadedImage(file);
        }
    };

    const sendMessage = async ({ currentProject, onMessageUpdate, onStreamEnd, uploadedImage }) => {
        let requestBody;
        let headers = {};

        

        if (uploadedImage) {
            requestBody = new FormData();
            requestBody.append('file', uploadedImage);
            requestBody.append('projectData', JSON.stringify(currentProject));
            requestBody.append('load_testing', false)
        } else {
            headers['Content-Type'] = 'application/json';

            let newRequestBody = {}
            
            if (id !== "1") {
                newRequestBody = {
                    ...currentProject,
                    load_testing: false 
                };
            } else {
                newRequestBody = {
                    ...currentProject,
                    load_testing: false,
                    product_specifications_selections: productSpecificationsSelections
                };
            }

            requestBody = JSON.stringify(newRequestBody);
        }

        try {
            abortControllerRef.current = new AbortController();
            setIsStreaming(true);

            const response = await fetch(process.env.REACT_APP_STREAM_API, {
                method: 'POST',
                headers: headers,
                body: requestBody,
                signal: abortControllerRef.current.signal
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            let buffer = '';

            while (true) {
                const { value, done } = await reader.read();
                if (done) break;

                const chunk = decoder.decode(value, { stream: true });
                buffer += chunk;

                // Split the buffer using the delimiter
                const parts = buffer.split('#1&');
                buffer = parts.pop(); // The last part may be incomplete

                for (const jsonString of parts) {
                    if (jsonString.trim() === '') continue;

                    try {
                        const parsedChunk = JSON.parse(jsonString);

                        // Existing processing logic
                        if (parsedChunk.new_message) {
                            onMessageUpdate({ new_message: true, role: parsedChunk.role });
                        }
                        if (parsedChunk.text_delta) {
                            onMessageUpdate({ text_delta: parsedChunk.text_delta });
                        }
                        if (parsedChunk.simulation_name_delta) {
                            onMessageUpdate({ simulation_name_delta: parsedChunk.simulation_name_delta });
                        }
                        if (parsedChunk.code_description_delta) {
                            onMessageUpdate({ code_description_delta: parsedChunk.code_description_delta });
                        }
                        if (parsedChunk.executable_python_code_delta) {
                            onMessageUpdate({ executable_python_code_delta: parsedChunk.executable_python_code_delta });
                        }
                        if (parsedChunk.progress_update) {
                            onMessageUpdate({ progress_update: parsedChunk.progress_update });
                        }
                        if (parsedChunk.notification) {
                            onMessageUpdate({ notification: parsedChunk.notification });
                        }
                        if (parsedChunk.diagram_accumulated) {
                            setCurrentProject((prevProject) => ({
                                ...prevProject,
                                elements: parsedChunk.diagram_accumulated.args.elements || prevProject.elements,
                                flowLines: parsedChunk.diagram_accumulated.args.flow_lines || prevProject.flowLines,
                                icons: parsedChunk.diagram_accumulated.args.icons || prevProject.icons,
                                diagramRemaining: parsedChunk.diagram_remaining || prevProject.diagramRemaining,
                            }));
                        }
                    } catch (error) {
                        if (process.env.REACT_APP_NODE_ENV !== 'production') {
                            console.error("Error parsing JSON string: ", jsonString, error);
                        }
                    }
                }
            }


            onStreamEnd();
        } catch (error) {
            if (process.env.REACT_APP_NODE_ENV !== "production") {
                console.log("there has been an error: ", error)
                if (error.name === 'AbortError') {
                    console.log('Fetch aborted');
                } else {
                    console.error('Fetch error:', error);
                }
            }
            
            // Perform cleanup regardless of the error type
            await handleCleanup();
        }
    };
    

    const getMessageClass = (role) => {
        switch (role) {
            case 'user':
                return 'user-message';
            case 'model':
                return 'model-message';
            case 'model-simblock':
                return 'model-simblock-message';
            case 'system-progress_update':
                return 'system-progress-message';
            case 'system-notification':
                return 'system-notification-message';
            default:
                return '';
        }
    };

    const renderMessage = (message, role) => {
        if (role === 'system-progress_update') {
            return (
                <div className="progress-update-message">
                    <span>{message}</span>
                </div>
            );
        } else if (role === 'system-notification') {
            return (
                <div className="system-notification-message">
                    <span>{message}</span>
                </div>
            );
        } else if (typeof message === 'string') {
            // Split by Python code, block math, and inline math
            const parts = message.split(/(\$\$[\s\S]*?\$\$|\$[^$]*\$|```python[\s\S]*?```)/).filter(Boolean);
    
            return parts.map((part, index) => {
                if (part.startsWith('```') && part.match(/^```python/)) {
                    // Python code block
                    const code = part.replace(/^```python/, '').replace(/```$/, '').trim();
                    return (
                        <SyntaxHighlighter
                            key={index}
                            language="python"
                            style={vscDarkPlus}
                        >
                            {code}
                        </SyntaxHighlighter>
                    );
                } else if (part.startsWith('$$') && part.endsWith('$$')) {
                    // Block math with KaTeX
                    const mathText = part.slice(2, -2).trim();
                    return (
                        <BlockMath key={index}>{mathText}</BlockMath>
                    );
                } else if (part.startsWith('$') && part.endsWith('$')) {
                    // Inline math with KaTeX
                    const mathText = part.slice(1, -1).trim();
                    return (
                        <span key={index} style={{ display: 'inline' }}>
                            <InlineMath>{mathText}</InlineMath>
                        </span>
                    );
                } else {
                    // Render other content as Markdown
                    return (
                        <ReactMarkdown
                            key={index}
                            remarkPlugins={[remarkGfm]}
                            components={{
                                a: ({ node, children, ...props }) => (
                                    <a {...props} target="_blank" rel="noopener noreferrer">
                                        {children}
                                    </a>
                                )
                            }}
                        >
                            {part}
                        </ReactMarkdown>
                    );
                }
            });
        } else if (typeof message === 'object') {
            return renderSimBlockMessage(message);
        }
    };
    

    const renderSimBlockMessage = (parts) => {
        const { simulation_name, code_description, executable_python_code } = parts;

        return (
            <div className="simblock-container">
                {simulation_name && (
                    <div className="simblock-field">
                        <h4>SIMULATION_NAME</h4>
                        <div>{simulation_name}</div>
                    </div>
                )}
                {code_description && (
                    <div className="simblock-field">
                        <h4>CODE_DESCRIPTION</h4>
                        <div>{code_description}</div>
                    </div>
                )}
                {executable_python_code && (
                    <div className="simblock-field">
                        <h4>EXECUTABLE_PYTHON_CODE</h4>
                        <div>
                            <CodeBlockWithCopyButton code={executable_python_code} />
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const CodeBlockWithCopyButton = ({ code }) => {
        const [buttonText, setButtonText] = useState('Copy');

        const handleCopyClick = () => {
            navigator.clipboard.writeText(code).then(() => {
                setButtonText('Copied');
                setTimeout(() => {
                    setButtonText('Copy');
                }, 2000);
            })
        };

        return (
            <div className="code-block-container">
                <button
                    className="copy-button"
                    onClick={handleCopyClick}
                >
                    {buttonText}
                </button>
                <SyntaxHighlighter
                    language="python"
                    style={vscDarkPlus}
                    className="custom-syntax-highlighter"
                >
                    {code.trim()}
                </SyntaxHighlighter>
            </div>
        );
    };

    return (
        <div className={`chat-bar ${isChatBarExpanded ? 'expanded' : ''}`}>
            <div className="models-container">
                <Models
                    selectedModel={defaultModel}
                    onSelectModel={handleModelSelect}
                    onToggleChatBar={handleToggleChatBar}
                    isChatBarExpanded={isChatBarExpanded}
                />
            </div>

            <div className="chat-messages" ref={chatMessagesRef} onScroll={handleScroll}>
                {currentProject.messages
                    .filter((message) =>
                        ['user', 'model', 'model-simblock', 'system-progress_update', 'system-notification'].includes(
                            message.role
                        )
                    )
                    .map((message, index) => (
                        <div key={index} className={`chat-message ${getMessageClass(message.role)}`}>
                            {renderMessage(message.parts[0], message.role)}
                        </div>
                    ))}
                {displayedMessages.map((message, index) => (
                    <div key={`displayed-${index}`} className={`chat-message ${getMessageClass(message.role)}`}>
                        {renderMessage(message.parts[0], message.role)}
                    </div>
                ))}
            </div>
            <div className="chat-input-container">
                {uploadedImage && (
                    <div className="image-preview">
                        <img src={URL.createObjectURL(uploadedImage)} alt="Preview" className="image-thumbnail" />
                    </div>
                )}
                {isStreaming ? (
                    <button className="down-button" onClick={handleDownButtonClick}>
                        <i className="bi bi-sort-down"></i>
                    </button>
                ) : (
                    <>
                        <input
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="image-upload"
                            onChange={handleImageUpload}
                        />
                        {/* 
                        <label htmlFor="image-upload" className="image-button">
                            <i className="bi bi-file-image"></i>
                        </label> 
                        */}
                    </>
                )}
                <textarea
                    ref={inputRef}
                    className="chat-input"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    placeholder="Write a message..."
                    onClick={resetSelections}
                />
                {isStreaming && (
                    <button className="stop-button" onClick={handleStopButtonClick}>
                        <i className="bi bi-stop-fill"></i>
                    </button>
                )}
                {isSendingMessage ? (
                    <div className="spinner-border send-button-spinner" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : (
                    <button
                        className="send-button disabled"
                        onClick={() => handleSendMessage()}
                        disabled={true}
                    >
                        <i className="bi bi-arrow-return-right"></i>
                    </button>
                )}
            </div>
            {/* Añade el texto informativo aquí */}
            <div className="ai-disclaimer">
                Artificial intelligence can make mistakes. Always verify important information.
            </div>
        </div>
    );
};

export default ChatBar;
